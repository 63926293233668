<template>
  <base-frame-setting>
    <template v-slot:title>{{ $route.name }}</template>
    <template v-slot:default>
      <div class="mt-5">
        <!-- START Inventory-list -->
        <div v-for="item in inventoryTypeList" :key="item.id" class="mb-3">
          <p class="text-title">
            {{ item.name }}
          </p>
          <div class="d-flex">
            <v-text-field
              :disabled="!checkPerUser"
              v-model="item.nameAbbr"
              :rules="nameRules"
              @change="isEdit = true"
              class="pa-0 ma-0 mr-2"
            />
            <v-btn
              :disabled="!checkPerUser"
              small
              color="var(--text__primary)"
              class="text--white"
              @click="handleUpdate(item.id, item.nameAbbr)"
            >
              {{ $t("buttons.save") }}
            </v-btn>
          </div>
        </div>
        <!-- END Inventory-list -->
      </div>
    </template>
  </base-frame-setting>
</template>

<script>
import BaseFrameSetting from '@/components/FrameSetting/BaseFrameSetting'
import { INVENTORY_TYPE_LIST } from '@/api/graphql/frameSetting/frame-setting-inventory'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { mapActions, mapMutations } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'SystemOuterFrameSetting',
  apollo: {
    inventoryTypeList: {
      query: gql`${INVENTORY_TYPE_LIST}`,
      variables () {
        return {
          onlyExternallyReserved: true
        }
      }
    }
  },
  components: {
    BaseFrameSetting
  },
  methods: {
    ...mapActions(['updateNameAbbr']),
    ...mapMutations(['setErrorDialog']),
    handleUpdate (id, nameAbbr) {
      if (nameAbbr && this.isEdit) {
        this.updateNameAbbr({ id, nameAbbr })
        this.isEdit = false
      }
    }
  },
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      inventoryTypeList: [],
      isEdit: false,
      nameRules: [v => !!v || this.$t('rules.isRequired'),
        v => v?.length < 256 || this.$t('rules.isInvalid')]
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-input {
    max-width: 300px;
  }
  .v-input__slot::before {
    background-color:#888888 !important
  }
}
.text-title {
  size: 8px;
  color: #000000;
  height: 11px;
  font-weight: bold;

}
</style>
